.bni.checkin-page{
    background: #fff;
    min-height: calc(100vh - 80px);
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .bg-danger{
    background: red;
  }
  .bni.checkin-page.bg-danger{
    min-height: 100vh;
  }

  .flex-1{
    flex: 1;
  }
  
  .divider{
    width: 60%;
    background: red;
    height: 8px;
    border-radius: 4px;
    margin-top: 20px;
    margin-inline: auto;
  }

  .bni-buttons .btn{
    font-size: 1.3rem;
    font-weight: 600;
    padding: 20px 15px;
  }
  
  .checkin-page .bni-header{
    background-color: #000;
    /* border-radius: 0 0 30px 30px; */
    padding: 30px 20px;
    width: 100%;
    overflow: hidden;
  }
  .checkin-page img{
    max-width: 100%;
    max-height: 100px;
  }
  .checkin-page .bni-header .admin-name{
    color: #fff;
    font-size: 1.5rem;
    font-weight: 600;
    text-align: center;
    text-wrap: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .checkin-page .body{
    flex: 1;
    flex-direction: column;
    width: 100%;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .checkin-page .body i{
    font-size: 80px;
    margin-bottom: 10px;
  }
  .checkin-page .body p{
    font-size: 1.2rem;
    font-weight: 600;
  }
  .checkin-page .body1{
    flex-direction: column;
    width: 100%;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .checkin-page .body2{
    flex-direction: column;
    width: 100%;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .checkin-page .body2 img{
    max-height: 15vh;
    max-width: 100%;
  }
  
  .checkin-page .MuiInputBase-input.MuiOutlinedInput-input.Mui-disabled{
    -webkit-text-fill-color: #000;
  }
  .w-40{
    width: 40%;
  }

  .clock{
    border-radius: 70px;
    border: 3px solid #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -50px;
    margin-top: -50px;
    display: block;
    width: 120px;
    height: 120px;
  }
  
  .clock:after{
    content: "";
    position: absolute;
    background-color: #fff;
    top: 13px;
    left: 48%;
    height: 50px;
    width: 4px;
    border-radius: 5px;
    -webkit-transform-origin: 50% 97%;
        transform-origin: 50% 97%;
    -webkit-animation: grdAiguille 2s linear infinite;
        animation: grdAiguille 2s linear infinite;
  }
  
  @-webkit-keyframes grdAiguille{
      0%{-webkit-transform:rotate(0deg);}
      100%{-webkit-transform:rotate(360deg);}
  }
  
  @keyframes grdAiguille{
      0%{transform:rotate(0deg);}
      100%{transform:rotate(360deg);}
  }
  
  .clock:before{
    content: "";
    position: absolute;
    background-color: #fff;
    top: 24px;
    left: 48%;
    height: 40px;
    width: 4px;
    border-radius: 5px;
    -webkit-transform-origin: 50% 94%;
        transform-origin: 50% 94%;
    -webkit-animation: ptAiguille 12s linear infinite;
        animation: ptAiguille 12s linear infinite;
  }
  
  @-webkit-keyframes ptAiguille{
      0%{-webkit-transform:rotate(0deg);}
      100%{-webkit-transform:rotate(360deg);}
  }
  
  @keyframes ptAiguille{
      0%{transform:rotate(0deg);}
      100%{transform:rotate(360deg);}
  }
*,::after,::before{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
#root{
  min-height: calc(100vh - 60px);
  max-height: 100vh;
}
body {
  margin: 0;
  background-color: #fff;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.bb2{
  border-bottom: 2px solid #ededed;
}

button.btn{
  text-transform: uppercase;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.overallPages{
  display: flex;
}
.components{
  display: flex;
  width: 100vw;
}
.fw-600{
  font-weight: 700;
}
.bg-light-cust{
  background: #f5f5f5;
}
.mainContainer{
  flex: 1;
}
.mobile .mainContainer{
  flex: 1;
  max-width: 100%;
}
.mobile .table-card{
  overflow: auto;
}
.mobile .tableContainer{
  min-width: 700px;
}

.loader-swal{
  background: transparent;
}

button, button.btn{
  text-transform: uppercase;
}
.sidebarContainer{
    width: 300px;
    height: calc(100vh - 80px);
    background-color: #fff;
    box-shadow: 0px 0px 0px 2px #e2e1e2;
    /* z-index: -1; */
    overflow: auto;
    display: flex;
    flex-direction: column;
}
.sidebarContainer p{
   font-weight: 500;
   font-size: 1rem;
}
.sidebarContainer p a{
   font-weight: 700;
}
.mobile .sidebarContainer{
    position: fixed;
    left: 0;
    top: 80px;
    background-color: #fff;
    width: 300px;
    height: calc(100vh - 80px);
    overflow: auto;
    z-index: 10;
}
.logoImage{
    text-align: center;
    padding: 15px 18px;
    box-shadow: 0 0 2px 2px #e2e1e2;
}
.sidebarContent{
    padding: 30px 0px;
}
.subContent{
    display: flex;
    align-items: center;
    padding: 10px 0;
    gap: 0 15px;
}
.icon{
    font-size: 20px;
    cursor: pointer;
    margin-left: 30px;
}

.actualText{
    margin: 0;
    font-size: 1.2rem;
    font-weight: 400;
    cursor: pointer;
}
.subContent.active,
.subContent:hover{
    background-color: #000;
    color: white;
}
.sidebar-link{
    color: black;
    text-decoration: none;
}
.checkin-page{
  background: #fff;
  min-height: calc(100vh - 60px);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.divider{
  width: 60%;
  background: red;
  height: 8px;
  border-radius: 4px;
  margin-top: 20px;
}

.checkin-page .header{
  background-color: #000;
  border-radius: 0 0 30px 30px;
  padding: 30px 20px;
  width: 100%;
  overflow: hidden;
}
.checkin-page img{
  max-width: 100%;
  max-height: 100px;
}
.checkin-page .header .admin-name{
  color: #fff;
  font-size: 1.5rem;
  font-weight: 600;
  text-align: center;
  text-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.checkin-page .body{
  flex: 1;
  flex-direction: column;
  width: 100%;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.checkin-page .body i{
  font-size: 80px;
  margin-bottom: 10px;
}
.checkin-page .body p{
  font-size: 1.2rem;
  font-weight: 600;
}
.checkin-page .body1{
  flex-direction: column;
  width: 100%;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.checkin-page .body2{
  flex-direction: column;
  width: 100%;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.checkin-page .body2 img{
  max-height: 15vh;
  max-width: 100%;
}

.checkin-page .MuiInputBase-input.MuiOutlinedInput-input.Mui-disabled{
  -webkit-text-fill-color: #000;
}
.w-40{
  width: 40%;
}

.checkin-page .btn.btn-dark.btn-black{
  background: #000;
}
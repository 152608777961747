nav{
    height: 80px;
    background-color: #fff;
    color: #000;
    width: 100vw;
    border-bottom: 2px solid #eae7e7;
}
.logo-image{
    width: 200px;
}
.navbar-brand{
    display: none;
}
.menu-icon{
    color: #000;
    cursor: pointer;
}
.mobile .navbar-brand{
    display: block;
}
.mobile nav{
    width: 100%;
}
.navbarContent{
    padding-left: 30px;
    padding-right: 30px;
}
.mobile .navbarContent{
    justify-content: space-between;
    width: 100%;
    flex-wrap: nowrap;
}
.navbarContainer{
    /* width: calc(100vw - 300px); */
}

#navbarSupportedContent{
    align-items: center;
    display: flex;
}
#navbarSupportedContent2{
    display: flex;
    align-items: center;
    justify-content: center;
}
.heading{
    font-weight: 600;
}
.navbar-lastText{
    font-size: 1.2rem;
    font-weight: bold;
}
.questionmark{
    font-weight: 600;
    font-size: 20px;
}
@media screen and (max-width: 992px) {
    .navbarContent{
        padding-left: 15px;
        padding-right: 15px;
    }
}
.attendenceContainer{
    padding: 30px;
    height: calc(100vh - 80px);
    /* width: calc(100vw - 300px); */
    background-color: #e2e1e2;
    overflow: auto;
}
.attendence-log{
    font-size: 1.5rem;
    font-weight: 600;
}
@media screen and (max-width: 992px) {
    .attendenceContainer{
        padding: 20px 15px;
    }
}
.reportsPage{
    padding: 30px;
    height: 100vh;
    background-color: #e2e1e2;
    overflow: auto;
}
.attendence-log{
    font-size: 1.5rem;
    font-weight: 600;
}
@media screen and (max-width: 992px) {
    .reportsPage{
        padding: 20px 15px;
    }
    .ofw-hid{
        overflow: auto;
    }
    .ofw-hid .tableContainer{
        min-width: 500px;
    }
    .ofw-hid .tableContainer table tbody tr td:first-child{
        max-width: 200px;
        overflow: hidden;
        text-wrap: nowrap;
        text-overflow: ellipsis;
    }
}